import React from "react"
import { Layout } from "../../components/Layout"
import { graphql } from "gatsby"
import { Subpage } from "../../components/Subpage"
import { Helmet } from "react-helmet"

const header = "Case studies"
const description =
  "Here we will show you how we approached the problems we encountered. How the process of solving them looks like and what results we obtained"

export default function CaseStudies({ data }) {
  const caseStudies = data.caseStudies.nodes
  console.log(caseStudies)

  return (
    <Layout>
      <Helmet htmlAttributes={{ lang: 'pl' }}>
        <meta charSet="utf-8"/>
        <title>OakDevices - Case studies</title>
        <meta
          name="description"
          content="Oakdevices - transform Your Business Into The Company Of The Future"
        />
      </Helmet>
      <Subpage
        header={header}
        description={description}
        elements={caseStudies}
      />
    </Layout>
  )
}

export const query = graphql`
  query CaseStudiesPage {
    caseStudies: allContentfulCaseStudy(
      sort: { fields: createdAt, order: DESC }
    ) {
      nodes {
        id
        title
        tags
        slug
        category
        shortDescription {
          childMarkdownRemark {
            html
          }
        }
        thumbnail {
          gatsbyImageData
        }
      }
    }
  }
`
